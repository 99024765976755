 @use "variables" as vars;

@mixin responsive($property, $values, $breakpoints: vars.$breakpoints) {
    @each $breakpoint_name, $breakpoint_width in $breakpoints {
        @media (min-width: $breakpoint_width) {
            #{$property}: map-get($values, $breakpoint_name);
        }
    }
}

@mixin responsive-fixed-flex($widths, $breakpoints: vars.$breakpoints) {
    @each $breakpoint_name, $breakpoint_width in $breakpoints {
        @media (min-width: $breakpoint_width) {
            flex: 0 0 map-get($widths, $breakpoint_name);
            min-width: map-get($widths, $breakpoint_name);
            width: map-get($widths, $breakpoint_name);
            max-width: map-get($widths, $breakpoint_name);
        }
    }
}

@mixin responsive-fixed-flex-height($heights, $breakpoints: vars.$breakpoints) {
    @each $breakpoint_name, $breakpoint_width in $breakpoints {
        @media (min-width: $breakpoint_width) {
            flex: 0 0 map-get($heights, $breakpoint_name);
            min-height: map-get($heights, $breakpoint_name);
            height: map-get($heights, $breakpoint_name);
            max-height: map-get($heights, $breakpoint_name);
        }
    }
}


@mixin color-and-background() {
    @each $color_name, $color_value in vars.$colors {
        &.color-#{$color_name} {
            color: $color_value;
        }
        &.background-color-#{$color_name} {
            background-color: $color_value;
        }
    }
}
