@use "mixins" as mixins;
@use "variables" as vars;


html {
    background-color: map-get(vars.$colors, "black");
    font-family: Raleway, Arial, sans-serif;
    line-height: 1.15;
    overflow-x: hidden;
    scroll-behavior: smooth;
width:100%;
}
    
.header-spacer {
    background-color: map-get(vars.$colors, "black");

    @include mixins.responsive(height, vars.$header_spacer_heights);

}

h1,
h2,
h3 {
    font-family: Poiret One;
    font-weight: 400;
}
.under-construction {
    pointer-events: none;
    position: fixed;
    right: 0;
    bottom: 0em;
    width: 20em;
    z-index: 99999999;
    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }
}

img.loading-image{
    z-index:-1;
    animation: waiting-spinner 3s infinite;
    left: 50%;
    top: 38.2%;
    transform: translate(-50%, -50%);
    height:2rem;
    width:2rem;
}

.container {
    margin: 0 auto;
    font-size: 1.3rem;
    @include mixins.responsive(width, vars.$container-widths);
    @include mixins.responsive(padding, vars.$container-paddings);
    /*overflow:hidden;*/
}
a[target="_blank"]:not(.no-arrow) {
    &:after {
        content: " ";

        display: inline-block;
        transform: translateY(-7px)  ;
        height:.66rem;
        width:.66rem;
        background: url('data:image/svg+xml;charset=UTF-8,<svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.12 21.07"><defs><style>.cls-1{fill:none;stroke:'+ map-get(vars.$colors-svg-encode,"black")+';stroke-miterlimit:10;}</style></defs><line class="cls-1" x1=".35" y1="20.71" x2="20.71" y2=".35"/><line class="cls-1" x1="17.63" y1="13.7" x2="20.63" y2=".74"/><line class="cls-1" x1="7.35" y1="3.53" x2="20.31" y2=".53"/></svg>')  no-repeat ;
    }
}


@each $color_name, $color_value in vars.$colors {
    .color-span.color-#{$color_name}, .color-#{$color_name}{
        a,a:hover,a:focus, a:visited,  .color-span.color-#{$color_name} {
            color: $color_value!important;

            &[target="_blank"]:not(.no-arrow) {
                &:after {
                    content: " ";

                    display: inline-block;
                    transform: translateY(-7px)  ;
                    height:.66rem;
                    width:.66rem;
                    background: url('data:image/svg+xml;charset=UTF-8,<svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.12 21.07"><defs><style>.cls-1{fill:none;stroke:'+ "#23ff0000"+';stroke-miterlimit:10;}</style></defs><line class="cls-1" x1=".35" y1="20.71" x2="20.71" y2=".35"/><line class="cls-1" x1="17.63" y1="13.7" x2="20.63" y2=".74"/><line class="cls-1" x1="7.35" y1="3.53" x2="20.31" y2=".53"/></svg>')  no-repeat ;
                }
            }

        }

        color: $color_value!important;


    }
}
//map-get(vars.$colors-svg-encode,$color_name)
@each $color_name, $color_value in vars.$color-mixes{
    .color-block.color-#{$color_name}, .button-#{$color_name}{
        background-color: map-get(vars.$colors,$color_name)!important;
        color: map-get(vars.$colors,$color_value)!important;

        display:  inline-block;
        position: relative;
        z-index: 1;
        font-family: Raleway;

        line-height: 1.5;
        margin: 1rem 0;

        padding:1rem 2rem 1rem 2rem;
    }
    .button-#{$color_name}:hover{
        transform: scale(1.03);
        /*         & a, & a:visited, & a:active,& a:focus,a:hover {
                     color: map-get(vars.$colors,$color_value)!important;
                 }*/
    }
    .button-#{$color_name}{
        cursor: pointer;
        a,a:visited,a:active,a:focus,a:hover {
            color:blue!important;
            /*color: map-get(vars.$colors,$color_value)!important;*/
        }
    }
}
a.anchor-target{
    display: block;
    position: relative;
    top: -8rem;
    // top-bar ausgleich für target scrolling
    visibility: hidden;
}
.anchor-target-direct{
    display: block;
    position: relative;
    top: -12rem;
    // top-bar ausgleich für target scrolling
    visibility: hidden;
}
@each $color_name, $color_value in vars.$colors-svg-encode {
     .color-#{$color_name}  
        a[href$=".pdf"]:not(.no-download) {
            text-decoration: none;
            &:after {
                content: " ";
                width:1em;
                height:1em;
                display:  inline-block;
                margin-left:0.3rem;
                background-image: url('data:image/svg+xml;charset=UTF-8,<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 89 85.4" > <style type="text/css">.download{fill:none; stroke-width:6;stroke:' + map-get(vars.$colors-svg-encode,$color_name)+ '}</style><line class="download" x1="44.5" y1="0" x2="44.5" y2="64"/><polyline class="download" points="17.4,38.9 44.5,66 71.6,38.9 "/><polyline class="download" points="3,68.2 3,82.4 86,82.4 86,68.2 "/></svg>');

            }

            background-repeat:no-repeat ;
            transform: translateY(.1rem);

         

        &:hover {
            /*text-decoration: underline;*/
            &:after {
                animation-name: pdf-link;
                animation-duration: 0.3s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
            }
        }
    }
}

a {
    position: relative;
    /*color: map-get(vars.$colors, "dark-grey");*/
    &:hover,
        &:focus {
        /*color: map-get(vars.$colors, "black");*/
    }


    &:hover,
        &:focus {
        &:after {
            animation-name: external-link;
            animation-duration: 0.3s;
            animation-direction: alternate;
            animation-iteration-count: infinite;
        }
    }

    &[href^="#glossar"]:not(.no-plus) {
        @each $color_name, $color_value in vars.$colors {
            &.glossary-symbol-color-#{$color_name}:after {
                color: $color_value;
            }
        }
        &:after {
            content: "+";
            display: inline-block;
            margin-left: 0.125rem;
            transform: scale(1.25) translateY(-0.25rem);
        }
        &:hover,
            &:focus {
            &:after {
                animation-name: glossary-link;
                animation-duration: 0.3s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
            }
        }
    }


}



figure a[target="_blank"]:not(.no-arrow) {
    &:after {
        display: none;
    }
}

@keyframes external-link {
    from {
        transform: translateY(-7px) ;
        //rotate(-45deg);
    }
    to {
        transform: translate(3px, -10px);
        // rotate(-45deg);
    }
}

@keyframes glossary-link {
    from {
        transform: scale(1.25) translateY(-0.25rem);
    }
    to {
        transform: scale(1.5) translateY(-0.25rem);
    }
}

@keyframes pdf-link {
    from {
        transform:   translateY(.2rem);
    }
    to {
        transform:   translateY( -0.1rem);
    }

}

.download-confirmation-backdrop {
    &.hidden {
        /*display: none;*/
        visibility: hidden;
        opacity: 0;
    }
    visibility: visible;
    opacity: 1;
    animation: fade 1s;
    transition: opacity 333ms, visibility 333ms;

    background-color: rgba(map-get(vars.$colors, "black"), 0.66);

    position: fixed;
    z-index: 50;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;
    & .download-confirmation-form {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: map-get(vars.$colors, "white");
        @include mixins.responsive(
        padding,
        vars.$download_confirmation_form_paddings
        );
        @include mixins.responsive(
        width,
        vars.$download_confirmation_form_widths
        );
        text-align: left;
        & .download-confirmation-filename {
            color: map-get(vars.$colors, "red");
            font-weight: 600;
        }
        & .download-confirmation-text {
            color: map-get(vars.$colors, "black");
            margin-bottom: 1.5rem;
            & a {
                color: rgba(map-get(vars.$colors, "red"), 0.75);
                &:hover,
                    &:focus {
                    color: map-get(vars.$colors, "red");
                }
                &:after {
                    display: none;
                }
            }
        }
        & button {
            border: none;
            color: map-get(vars.$colors, "white");
            cursor: pointer;
            padding: 0.5rem 1.5rem;

            @include mixins.responsive(
            width,
            vars.$download_confirmation_button_widths
            );
            &.download-confirmation-close-button {
                background-color: map-get(vars.$colors, "dark-grey");
                &:hover,
                    &:focus {
                    background-color: map-get(vars.$colors, "black");
                }
                @include mixins.responsive(
                margin,
                vars.$download_confirmation_close_button_margins
                );
            }
            &.download-confirmation-download-button {
                background-color: rgba(map-get(vars.$colors, "red"), 0.9);
                &:hover,
                    &:focus {
                    background-color: map-get(vars.$colors, "red");
                }
                & svg {
                    display: inline-block;
                    margin-left: 0.5rem;
                    stroke: map-get(vars.$colors, "white");
                    fill: map-get(vars.$colors, "white");
                    height: 15px;
                    width: auto;
                }
            }
        }
        & .loading-spinner {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            height: 80px;
            &:after {
                content: "";
                display: block;
                width: 64px;
                height: 64px;
                margin: 8px;
                border-radius: 50%;
                border: 6px solid map-get(vars.$colors, "dark-grey");
                border-color: map-get(vars.$colors, "dark-grey") transparent
                    map-get(vars.$colors, "dark-grey") transparent;
                animation: waiting-spinner 1.2s linear infinite;
            }
            &.hidden {
                display: none;
            }
        }
    }
}

.has-lightbox + .lightbox-item {
    display: none;
}





nav.main ul li:hover ul {
    display: block;

}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes out {
    0% {
        opacity:1;
    }

    100% {
        opacity: 0;
    }
}

.lightbox {
    &.hidden {
        /*display: none;*/
        visibility: hidden;
        opacity: 0;
    }
    visibility: visible;
    opacity: 1;
    animation: fade 1s;
    transition: opacity 333ms, visibility 333ms;
    position: fixed;
    z-index: 25;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(map-get(vars.$colors, "black"), 0.85);
    @include mixins.responsive(padding, vars.$lightbox_paddings);
    & .lightbox-content {
        position: relative;
        background-color: map-get(vars.$colors, "black");
        height: 100%;
        overflow: hidden;
        & .lightbox-items-wrapper {
            display: flex;
            height: 100%;
            &.transitioning {
                transition: transform 0.33s ease-in-out;
            }
            & .lightbox-item {
                flex: 0 0 100%;
                width: 100%;
                height: 100%;
                padding: 1rem;
                display: flex;
                flex-direction: column;
                & .lightbox-image-wrapper {
                    flex: 1 1 100px;
                    & .lightbox-image {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        & img {
                            position: absolute;
                            display: block;
                            max-width: 100%;
                            max-height: 100%;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            /*                            z-index:2;*/
                        }

                    }
                }
                & .lightbox-caption-wrapper {
                    flex: 0 0 auto;
                    padding: 1rem 0 0 0;
                    font-size: 0.8rem;
                    & .lightbox-caption-text {
                        /*color: map-get(vars.$colors, "yellow");*/
                        margin-bottom: 0.5rem;

                        font-size:1.2em;

                        span{
                            margin:0 0.3rem 0 0.3rem;
                            display:inline-block;
                            &.title{
                                margin-left:0;
                                font-family: Poiret One;
                                display: inline-block;
                                @include mixins.responsive(
                                font-size,
                                vars.$lightbox_caption_text_title_font_sizes
                                );
                                font-weight: bold;

                            }
                            @media (max-width: map-get(vars.$breakpoints, "md")) {
                                &.title{
                                    margin:0 0 0 .2rem;
                                    display:block;
                                }
                                &.divider{
                                    display:none;
                                }
                            }
                            &.location{
                                color: map-get(vars.$colors, "white");
                                font-family: Poiret One;
                                @include mixins.responsive(
                                font-size,
                                vars.$lightbox_caption_text_subtitle_font_sizes
                                );
                            }
                            &.comment{
                                color: map-get(vars.$colors, "light-grey");
                                font-family: Poiret One;
                                @include mixins.responsive(
                                font-size,
                                vars.$lightbox_caption_text_subtitle_font_sizes
                                );

                            }
                        }
                    }
                    & .lightbox-caption-info {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        @include mixins.responsive(
                        font-size,
                        vars.$lightbox_caption_text_font_sizes
                        );
                        & .lightbox-caption-legal {
                            color: map-get(vars.$colors, "light-grey");
                            span {
                                @include mixins.responsive(
                                font-size,
                                vars.$lightbox_caption_text_font_sizes
                                );
                            }
                        }

                        .label{
                        }


                        & .lightbox-caption-download {
                            text-align: right;
                            margin-left: auto;
                            & .download-button {
                                .download-button {

                                }
                                display: inline-block;
                                background: transparent;
                                cursor: pointer;
                                border: none;
                                text-decoration: underline;
                                margin: 0.5rem 0 0.5rem auto;
                                padding: 0;
                                color: map-get(vars.$colors, "light-grey");
                                &:hover,
                                    &:focus {
                                    color: map-get(vars.$colors, "white");
                                }
                                svg {
                                    width: 25px;
                                    height: 25px;
                                    & line, & polyline{
                                        stroke: map-get(vars.$colors, "white");
                                    }
                                    &:first-child {
                                        transform: translateX(5px);
                                    }
                                }

                            }
                        }

                    }
                    .image-counter{
                        display:inline-block;
                        margin-right:.5rem;
                        color:  map-get(vars.$colors, "white");
                    }
                }
            }
        }
        & .button {
            position: absolute;
            background: transparent;
            color: map-get(vars.$colors, "light-grey");
            border: none;
            font-size: 3rem;
            margin: 0;
            padding: 0;
            cursor: pointer;

            &.navigation-button {
                top: 40%;
                transform: translateY(-50%);
                @include mixins.responsive(
                width,
                vars.$lightbox_navigation_button_sizes
                );
                @include mixins.responsive(
                height,
                vars.$lightbox_navigation_button_sizes
                );
                &:after {
                    content: "";
                    position: absolute;
                    left: 37.5%;
                    top: 25%;
                    transform: translate(-50%, -50%);
                    width: 50%;
                    height: 50%;
                    border: 3px solid map-get(vars.$colors, "light-grey");
                    border-right-color: transparent;
                    border-bottom-color: transparent;
                }
                &.previous-item {
                    @include mixins.responsive(
                    left,
                    vars.$lightbox_navigation_button_offsets
                    );
                    &:after {
                        transform: rotate(-45deg);
                    }
                }
                &.next-item {
                    @include mixins.responsive(
                    right,
                    vars.$lightbox_navigation_button_offsets
                    );
                    &:after {
                        transform: rotate(135deg);
                        left: auto;
                        right: 37.5%;
                    }
                }

                &.hidden {
                    display: none;
                }
            }
            &.close-lightbox {
                top: 0;
                right: 0;
                padding: 1rem 2rem;
                font-weight: 600;

            }

            &.close-lightbox:after
                {
                content:" ";
                display: block;
                height:2em;
                width:2em;
                transform: rotate(45deg);
                background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px"><rect fill="' +  map-get(vars.$colors-svg-encode, "light-grey")+ '" x="25" width="1" height="50"/><rect  fill="' + map-get(vars.$colors-svg-encode, "light-grey") + '" y="25" width="50" height="1"/></svg>')  no-repeat ;
            }




        }
    }
}

.navigation-button-mobile {
    @media (min-width: map-get(vars.$breakpoints, "lg")) {
        display: none;
    }
    position: absolute;
    background: transparent;
    color: map-get(vars.$colors, "light-grey");
    border: none;
    font-size: 3rem;
    margin: 0;

    padding: 0;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    @include mixins.responsive(width, vars.$lightbox_navigation_button_sizes);
    @include mixins.responsive(height, vars.$lightbox_navigation_button_sizes);
    &:after {
        content: "";
        position: absolute;
        left: 37.5%;
        top: 25%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
        border: 3px solid map-get(vars.$colors, "light-grey");
        border-right-color: transparent;
        border-bottom-color: transparent;
    }
    &.previous-item {
        @include mixins.responsive(
        left,
        vars.$lightbox_navigation_button_mobile_offsets
        );
        &:after {
            transform: rotate(-45deg);
        }
    }
    &.next-item {
        @include mixins.responsive(
        right,
        vars.$lightbox_navigation_button_mobile_offsets
        );
        &:after {
            transform: rotate(135deg);
            left: auto;
            right: 37.5%;
        }
    }
    &.hidden {
        display: none;
    }
}



@keyframes waiting-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@keyframes sweep {
    0%    {
        opacity: 0;
        transform:translateX(-20px);
        max-height: 0px
    }
    100%  {
        opacity: 1;
        transform:translateX(0px);
        max-height: 10000px
    }

}
@-webkit-keyframes sweep {
    0%    {
        opacity: 0;
        -webkit-transform:translateX(-20px);
        max-height: 0px
    }
    100%  {
        opacity: 1;
        -webkit-transform:translateX(0px);
        max-height: 1000px
    }
}
@keyframes sweepoff {
    0%   {
        opacity: 1;
        transform:translateX(0px);
        max-height: 1000px
    }
    100% {
        opacity: 0;
        transform:translateX(-20px);
        max-height: 0px
    }

}
@-webkit-keyframes sweepoff {
    0%  {
        opacity: 1;
        -webkit-transform:translateX(0px);
        max-height: 1000px
    }
    100%    {
        opacity: 0;
        -webkit-transform:translateX(-20px);
        max-height: 0px
    }

}
@keyframes sweep2 {
    0%    {
        opacity: 0;
        transform:translateY(20px);
        max-height: 0px
    }
    100%  {
        opacity: 1;
        transform:translateY(0px);
        max-height: 1000px
    }

}
@-webkit-keyframes sweep2 {
    0%    {
        opacity: 0;
        -webkit-transform:translateY(20px);
        max-height: 0px
    }
    100%  {
        opacity: 1;
        -webkit-transform:translateY(0px);
        max-height: 1000px
    }
}
@keyframes sweepoff2 {
    0%   {
        opacity: 1;
        transform:translateY(0px);
        max-height: 1000px
    }
    100% {
        opacity: 0;
        transform:translateY(20px);
        max-height: 0px
    }

}
@-webkit-keyframes sweepoff2 {
    0%  {
        opacity: 1;
        -webkit-transform:translateY(0px);
        max-height: 1000px
    }
    100%    {
        opacity: 0;
        -webkit-transform:translateY(20px);
        max-height: 0px
    }

}


@-webkit-keyframes iconrotate {
    0%  {
        -webkit-transform: rotate(0deg);
    }
    100%    {
        -webkit-transform: rotate(45deg);
    }

}
@keyframes iconrotate {
    0%  {
        transform: rotate(0deg);
    }
    100%    {
        transform: rotate(45deg);
    }

}
@-webkit-keyframes iconrotate {
    0%  {
        -webkit-transform: rotate(0deg);
    }
    100%    {
        -webkit-transform: rotate(45deg);
    }

}
@keyframes iconrotateback {
    0%  {
        transform: rotate(45deg);
    }
    100%    {
        transform: rotate(0deg);
    }

}
@-webkit-keyframes iconrotateback {
    0%  {
        -webkit-transform: rotate(45deg);
    }
    100%    {
        -webkit-transform: rotate(0deg);
    }

}
