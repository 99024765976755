@use "sass:math";
@use "variables" as vars;
@use "mixins" as mixins;

a.skip-to-main-content {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    text-decoration: none;
    &:focus {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        padding: 5px 20px;
        z-index: 1001;
        border: 2px solid map-get(vars.$colors, "white");
        background-color: map-get(vars.$colors, "black");
        color: map-get(vars.$colors, "white");
    }
}


.top-bar {
    position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    width: 100%;
    background-color: map-get(vars.$colors, "black");
    color: map-get(vars.$colors, "white");
    font-size: 1.25rem;
    @include mixins.responsive(padding, vars.$top_bar_padding);
    transition: padding 0.5s ease-in;
    &.closed, #toc{
        transition: opacity .7s ease;
        opacity:0;
    }
    &.minimized {
        @include mixins.responsive(padding, vars.$top_bar_padding_minimized);
        & .top-bar-item .navigation-open-close-button {
        }
        & .top-bar-left-wrapper .logo svg {
            @include mixins.responsive(height, vars.$logo_height_minimized);
        }
        #toc{
            opacity:1;
        }
    }
    display: flex;
    justify-content: space-between;


    & .top-bar-left-wrapper {

        display: inline-block;
        background-color: map-get(vars.$colors, "black");
        & .logo {
            display: block;
            & svg, & img {
                transition: height 0.5s ease-in;
                @include mixins.responsive(height, vars.$logo_height);
                width: auto;
                display: block;
                path, line, circle{
                    fill:map-get(vars.$colors, "white");
                }
            }

            & #left{
                animation-name: logo-circle-right;
                animation-duration: 60s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-delay: 60s;
            }
            & #main{
                animation-name: logo-colors;
                animation-duration: 120s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-delay: 30s
            }
            & #right{
                //rechts
                animation-name: logo-circle-right;
                animation-duration: 30s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-delay: 45s

            }
        }
    }
    @for $i from 1 through 16 {
        #d#{$i} {
            animation-name: logo-letter-colors;
            animation-duration: 32.0s;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            animation-delay:20s +  $i * 1s;
        }
    }



    @keyframes logo-circle-left {
        0% {
            cy:60%;
        }
        100% {
            cy:40%;
        }
    }
    @keyframes logo-circle-right {
        0% {
            cy:60%;
            fill:  map-get(vars.$colors, "white");
        }
        25%{
            fill:  map-get(vars.$colors, "enactments");

        }
        50%{
            fill: map-get(vars.$colors, "culturaleducation");
        }
        75%{
            fill:   map-get(vars.$colors, "sociocultural");
        }
        100%{
            cy:40%;
            fill:  map-get(vars.$colors, "white");
        }


        /*        0% {
                    cy:60%;
                }
                100% {
                    cy:40%;
                }*/

    }
    @keyframes logo-letter-colors {

        0%{
            fill:  map-get(vars.$colors, "enactments");

        }
        50%{
            fill: map-get(vars.$colors, "culturaleducation");
        }
        100%{
            fill:   map-get(vars.$colors, "sociocultural");
        }

    }

    @keyframes logo-colors {
        0% {
            fill:  map-get(vars.$colors, "white");
        }
        25%{
            fill:  map-get(vars.$colors, "enactments");

        }
        50%{
            fill: map-get(vars.$colors, "culturaleducation");
        }
        75%{
            fill:   map-get(vars.$colors, "sociocultural");
        }
        100%{
            fill:  map-get(vars.$colors, "white");
        }
    }


    & .top-bar-right-wrapper {
        display: flex;
        align-items: center;
        & .top-bar-item {
            line-height: 1;
            padding: 0 1rem;
            & .top-bar-search {

                position: relative;
                & .search-button {
                    display: inline-block;
                    /*                    line-height: 1;*/
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    /*margin-top: 0.6em;*/
                    vertical-align: middle;
                    cursor: pointer;
                    & svg {
                        display: block;
                        @include mixins.responsive(
                        height,
                        vars.$top_bar_search_button_size
                        );
                        width: auto;
                        & path , & rect, & path, & polyline ,& line {
                            fill: map-get(vars.$colors, "light-grey");
                        }
                    }
                    &:hover,
                        &:focus {
                        & svg path , & svg rect, & svg polyline, & svg line {
                            fill: map-get(vars.$colors, "white");
                        }
                    }
                }
                & .search-input-wrapper {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right:2rem;
                    width: 0;
                    margin-bottom:-.8rem;
                    max-width:500px;
                    transition: width .7s ease-in-out;

                    & input {
                        overflow: hidden;
                        width: 100%;
                        border:0;
                        padding:0;
                        height:2em;
                        outline: 0 !important;
                        transition: border .7s ease-in-out, padding .7s ease-in-out;
                        margin-bottom:0.1rem;
                    }

                    & input.search-checkbox {

                        display: block;
                        position: absolute;
                        -webkit-appearance: none;


                        & + label  {
                            position: relative;
                            display:inline-block;
                            overflow: hidden;
                            font-size: .8rem;
                            width: 100%;
                            border: 0;
                            padding: 0;
                            /*height: 2em;*/
                            outline: 0 !important;
                            white-space: nowrap;
                        }
                        & + label:before {
                            /*pointer-events: all;*/
                            vertical-align: middle;
                            padding-right:.5em;
                            width: 1.3em;
                            height: 1.3em;
                            background: url("./icons/circle-white.svg") no-repeat ;
                            display:   inline-block ;
                            content:" ";
                            /*margin-left:-1.5em;*/

                        }


                    }
                    & input.search-checkbox:checked + label:before {

                        background : url("./icons/circlex-white-wringe.svg") no-repeat ;
                    }


                }
                &.opened .search-input-wrapper {
                    /*display: block;*/
                    width: 20em;

                    & input {
                        width: 100%;
                        border:0;
                        padding:.2em;
                        /*font-size: 1rem;*/
                    }
                }
            }
            & .calendar-link {
                text-decoration: none;
                outline: none;
                vertical-align: top;
                height: 2.5rem;
                top:-0.1em;
                right:-.2em;
                & svg {
                    display: block;
                    @include mixins.responsive(
                    height,
                    vars.$top_bar_calendar_link_size
                    );
                    width: auto;
                    line,  rect  {
                        fill: map-get(vars.$colors, "light-grey");
                    }
                }
                &:hover,
                    &:focus {
                    & svg line, & svg rect {
                        fill: map-get(vars.$colors, "white");
                    }
                }
            }
            & .navigation-open-close-button {
                position: relative;
                vertical-align: middle;
                width: 46px;
                height: 2.5rem;
                cursor: pointer;
                & .navigation-button-bar {
                    position: absolute;
                    background-color: map-get(vars.$colors, "light-grey");
                    transform-origin: 50% 50%;
                    transition: transform 0.25s ease-in, top 0.25s ease-in,
                        opacity 0.25s ease-in;
                    width: 30px;
                    height: 1px;
                    &:nth-child(1) {
                        top: 8px;
                        left: 8px;
                    }
                    &:nth-child(2) {
                        top: 18px;
                        left: 8px;
                    }
                    &:nth-child(3) {
                        top: 28px;
                        left: 8px;
                    }
                }
                &:hover,
                    &:focus {
                    & .navigation-button-bar {
                        background-color: map-get(vars.$colors, "white");
                    }
                }
                &.opened {
                    & .navigation-button-bar {
                        &:nth-child(1) {
                            top: 18px;
                            left: 8px;
                            transform: rotate(45deg);
                        }
                        &:nth-child(2) {
                            opacity: 0;
                        }
                        &:nth-child(3) {
                            top: 18px;
                            left: 8px;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
            &.top-bar-item-navigation-button {
                z-index: 10;
            }
            &.top-bar-item-language-select {
                @include mixins.responsive(
                display,
                vars.$top_bar_language_select_display
                );
            }
            &.top-bar-item-calendar-link, &.top-bar-item-search {
                @include mixins.responsive(
                display,
                vars.$top_bar_calendar_link_display
                );
            }

        }
    }
    & .site-navigation {
        position: fixed;
        transition: width 0.25s ease-in;
        overflow: hidden;
        background-color: rgba(map-get(vars.$colors, "black"), 0.5);
        z-index: 5;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0;
        overflow-y: auto;
        &.opened {
            width: 100%;
        }
        & .site-navigation-inner {
            margin-left: auto;
            @include mixins.responsive(width, vars.$site_navigation_width);
            height: auto;;
            min-height: 100vh;
            background-color: map-get(vars.$colors, "black");
            padding-bottom: 2rem;
            & .nav-search {
                margin: 0;
                @include mixins.responsive(
                padding,
                vars.$site_navigation_search_padding
                );
                /*position: relative;*/
                & .search-button {
                    display: inline-block;
                    /*                    line-height: 1;*/
                    background-color: transparent;
                    border: none;
                    padding: 0 0.6em 0.6em  0 ;
                    /*margin-top: 0.6em;*/
                    vertical-align: middle;
                    cursor: pointer;
                    & svg {
                        display: block;
                        @include mixins.responsive(
                        height,
                        vars.$top_bar_search_button_size
                        );
                        width: auto;
                        & path {
                            fill: map-get(vars.$colors, "light-grey");
                        }
                    }
                    &:hover,
                        &:focus {
                        & svg path {
                            fill: map-get(vars.$colors, "white");
                        }
                    }
                }
                & .search-input-wrapper {
                    display: inline-block;
                    vertical-align: top;
                    margin-right:.5rem;
                    width: 0;

                    max-width:90%;
                    transition: width .7s ease-in-out;
                    & input {
                        overflow: hidden;
                        width: 100%;
                        border: 0;
                        padding: 0;
                        height: 2em;
                        outline: 0 !important;
                        transition: border .7s ease-in-out, padding .7s ease-in-out;
                        margin-bottom:.2rem;
                    }
                    & input.search-checkbox {

                        display: block;
                        position: absolute;
                        -webkit-appearance: none;


                        & + label  {
                            position: relative;
                            display:inline-block;
                            overflow: hidden;
                            font-size: .8rem;
                            width: 100%;
                            border: 0;
                            padding: 0;
                            /*height: 2em;*/
                            outline: 0 !important;
                            white-space: nowrap;
                        }
                        & + label:before {
                            /*pointer-events: all;*/
                            vertical-align: middle;
                            padding-right:.5em;
                            width: 1.3em;
                            height: 1.3em;
                            background: url("./icons/circle-white.svg") no-repeat ;
                            display:   inline-block ;
                            content:" ";
                            /*margin-left:-1.5em;*/

                        }


                    }
                    & input.search-checkbox:checked + label:before {

                        background : url("./icons/circlex-white-wringe.svg") no-repeat ;
                    }


                }
                &.opened .search-input-wrapper {
                    /*display: block;*/
                    width: 20em;

                    & input {
                        width: 100%;
                        border:0;
                        padding:.2em;
                        /*font-size: 1rem;*/
                    }
                }
            }


            & .site-navigation-list {
                margin: 0;
                @include mixins.responsive(
                padding,
                vars.$site_navigation_list_padding 
                );
                list-style-type: none;
                font-size: 1.5rem;
                & .site-navigation-list-item {
                    margin: 0.25rem 0;
                    padding: 0.25rem 0;
                    & a {
                        text-decoration: none;
                        color: map-get(vars.$colors, "light-grey");
                        &:hover,
                            &:focus {
                            color: map-get(vars.$colors, "white");
                        }
                    }
                }
            }
            & .projects-button{
                @include mixins.responsive(
                padding,
                vars.$site_navigation_list_padding
                );
                margin: 0.25rem 0;
                    padding: 0.25rem 0;
                    font-size: 1.5rem;
                &:hover .projects{
                    display:block;
                     
                }
                .projects{
                    display:none;
                    padding:0;
                    @each $color_name, $color_value in vars.$color-mixes{
                        li.category-#{$color_name}{
                            width:30%;
                            font-size: .8rem;
                            overflow: hidden;
                            white-space: nowrap;

                            /*height:1em;*/
                            margin:.1rem;
                            padding:0.2rem;
                            display:inline-block;
                            background-color: map-get(vars.$colors,$color_name);
                            color: map-get(vars.$colors,"black");
                            text-overflow: ellipsis;

                            a,a:visited,a:active{
                                color: map-get(vars.$colors,"black");
                                text-decoration: none;


                            }
                        }
                    }
                }
            }
        }
    }
    & + .top-bar-spacer {
        /*display:none;*/
        @include mixins.responsive(padding-top, vars.$top_bar_spacer_height);
    }
}

.language-select {
    position: absolute;
    float: right;
    right: .5rem;
     @include mixins.responsive(
                top,
                vars.$site_navigation_language_padding 
                );
     

    & .language-select-button {
        display: block; 
        line-height: 1;
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        color: map-get(vars.$colors, "light-grey");
        &:hover,
            &:focus {
            color: map-get(vars.$colors, "white");
        }
    }
    & .languages-list {

        display: block;
        list-style-type: none;
        margin: 0.5rem 0;
        padding: 0 0.5rem;

        & li {
            list-style-type: none;
            display:  block;
            font-size: 1.33rem;
            width:2em;
            text-align:center;
            margin : 0.25rem ;
            padding: 0.25rem ;
            background-color:map-get(vars.$colors, "dark-grey");
            & a {
                display: flex;
                justify-content: center;
                text-decoration: none;
                color: map-get(vars.$colors, "light-grey");
                &:hover,
                    &:focus {
                    color: map-get(vars.$colors, "white");
                }
            }
            &.active a {
                color: map-get(vars.$colors, "white");
            }
        }
    }
    &.opened {
        & .languages-list {
            display: block;
        }
    }

    /*    & .languages-list {
            display: none;
            list-style-type: none;
            margin: 0.5rem 0;
            padding: 0 0.5rem;
            background-color: map-get(vars.$colors, "black");
            border: 1px solid map-get(vars.$colors, "medium-grey");
            position: absolute;
            left: 0;
            top: 100%;
            & li {
                font-size: 1rem;
                margin: 0.25rem 0;
                padding: 0.25rem 0;
                & a {
                    text-decoration: none;
                    color: map-get(vars.$colors, "light-grey");
                    &:hover,
                        &:focus {
                        color: map-get(vars.$colors, "white");
                    }
                }
                &.active a {
                    color: map-get(vars.$colors, "white");
                }
            }
        }
        &.opened {
            & .languages-list {
                display: block;
            }
        }*/
}

.sidebar-gallery-wrapper {
    position: fixed;
    z-index: 4;
    right: -2px;
    width: 0;
    &.opened {
        z-index: 5;
        right: 0;
        @include mixins.responsive(width, vars.$sidebar_gallery_width);
        & .sidebar-gallery-button {
            @include mixins.responsive(right, vars.$sidebar_gallery_width);

        }
        transition: width 0.25s ease-in-out, z-index 0s ease-in 0s;
    }
    height: 100vh;
    top: 0;
    background-color: map-get(vars.$colors, "black");
    border-left: 2px solid map-get(vars.$colors, "yellow");
    overflow: hidden;
    transition: width 0.25s ease-in, z-index 0s ease-in 0.25s;
    & .sidebar-gallery-button {
        position: fixed;
        font-family: Poiret One;
        z-index: 2;
        bottom: 2rem;
        right: 0;
        transition: right 0.25s ease-in;
        transform: translateX(-2rem);
        color: map-get(vars.$colors, "light-grey");
        cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: -0.5rem;
            width: 2.5rem;
            height: 2.5rem;
            top: 50%;
            transform: translateY(-50%);
            background-color: map-get(vars.$colors, "red");

        }
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            right: -0.5rem;
            width: calc(100% - 1.5rem);
            height: 2.5rem;
            top: 50%;
            transform: translateY(-50%);
            background-color: map-get(vars.$colors, "black");
        }
    }
    & .sidebar-gallery {
        @include mixins.responsive(width, vars.$sidebar_gallery_width);
        & img {
            @include mixins.responsive(
            padding,
            vars.$sidebar_gallery_image_padding
            );
            width: 100%;
        }
    }
}

.top-bar-overlay{

    display: block;
    position:absolute;
    max-width:45%;
    position: fixed;
    left: 50% ;
    /*width: 1920px;*/
    transform: translateX(-50%);
    background-color: map-get(vars.$colors, "black");
    overflow-x:hidden;
    overflow-y: hidden;
    transition: opacity .7s ease;
    &.closed {

        opacity:0;
    }

    #toc {
        overflow :hidden;
        position:relative;
        font-size: .8em;
        display: block;
        width: 10000px;

        &.unvisible{
            display:none;
        }

        @include mixins.responsive(height, vars.$logo_height_minimized);
        ul{
            width: 10000px;
            padding:0;
            transition: transform .5s ease-in-out;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;

            li{
                height:5.4em;
                display:  inline-block ;
                float:left;
                position: relative;
                list-style: none;
                margin:0;
                text-align: center;
                padding-left:.6em;
                padding-right:.6em;
                padding-top:2em;
                color: map-get(vars.$colors, "white")!important;
                border-top: #aaa solid .1em;
                bottom: -2.5em  ;
                transition:bottom .5s ease-in-out,padding-top .5s ease-in-out;

                background:black;
                a, &:hover, &:active, &:visited {
                    color: map-get(vars.$colors, "white");
                    text-decoration: none;
                    display:flex ;
                    text-align: center;
                    position: relative;

                }
                &:hover, &.visible, &.up{
                    cursor: pointer;
                    bottom:-1em;
                    padding-top:.4em;

                }
                &.up:hover{
                    bottom:-.8em;
                }
                &:hover{
                    @media (hover: none) {

                    }
                }
            }
        }

    }
}

@media (max-width: map-get(vars.$breakpoints, "md")) {
    .top-bar-overlay{

        @include mixins.responsive(top, vars.$logo_height_minimized);
        display: block;
        position:absolute;
        max-width:100%;
        left: 0%;
        transform: translateX(0);
        overflow-x:hidden;

        &.minimized {
            overflow-x:auto;
        }
        #toc {
            /*background: yellow; ;*/
            height:4em;
            ul{
                padding-top:.4em;
                li{
                    bottom:.5em  ;
                    padding-top:.5em;
                    bottom:0em;
                    a, &:hover, &:active, &:visited {
                    }
                    &:hover, &.visible, &.up{
                        bottom:0.2em  ;
                        padding-top:.5em;
                        & a  {
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: map-get(vars.$breakpoints, "md")) and  (max-width: map-get(vars.$breakpoints, "lg")) {
    .top-bar-overlay{


        &.minimized {
        }
        #toc {
            /*background: green ;*/
            ul{
                li{
                    bottom:-1.4em;
                    a, &:hover, &:active, &:visited {
                    }
                    &:hover, &.visible, &.up{
                        bottom:0.1em  ;
                        & a  {
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: map-get(vars.$breakpoints, "lg")) and  (max-width: map-get(vars.$breakpoints, "xl")) {
    .top-bar-overlay{
        max-width:55%;

        &.minimized {
        }
        #toc {
            /*background: red ;*/
            ul{
                li{
                    bottom:-1.4em;
                    a, &:hover, &:active, &:visited {
                    }
                    &:hover, &.visible, &.up{
                        bottom:0em  ;
                        & a  {
                        }
                    }
                }
            }
        }
    }
}

