 @use "variables" as vars;
@use "mixins" as mixins;

.footer-wrapper {
    background-color: map-get(vars.$colors, "black");
    color: map-get(vars.$colors, "light-grey");
    line-height: 1.35;
    border-top: 2px solid map-get(vars.$colors, "light-grey");
    & .footer {
        @include mixins.responsive(padding, vars.$footer_padding);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & a {
            text-decoration: none;
            color: map-get(vars.$colors, "light-grey");
            &:hover,
            &:focus {
                color: map-get(vars.$colors, "white");
            }
        }
        & figure {
            display: inline-block;
            margin: 0;
            & a {
                display: block;
                & img {
                    display: block;
                }
            }
        }
        & .column {
            @include mixins.responsive-fixed-flex(vars.$footer_column_widths);
        }
        & .column-center {
            @include mixins.responsive(
                margin,
                vars.$footer_column_center_margins
            );
            & img {
                width: 150px;
            }
        }
        & .column-right {
            & figure {
                display: inline-block;
                margin: 0.5rem 0.25rem;
                & img {
                    height: 40px;
                }
            }
            & a {
                display: block;
                margin: 0.5rem 0;
            }
            & a[target="_blank"] {
                margin: 0;
            }
        }
    }
}
