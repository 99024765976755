 /* poiret-one-regular - latin */
@font-face {
    font-family: "Poiret One";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/poiret-one-v9-latin-regular.eot");
    src: local(""),
        url("../fonts/poiret-one-v9-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/poiret-one-v9-latin-regular.woff2") format("woff2"),
        url("../fonts/poiret-one-v9-latin-regular.woff") format("woff"),
        url("../fonts/poiret-one-v9-latin-regular.ttf") format("truetype"),
        url("../fonts/poiret-one-v9-latin-regular.svg#PoiretOne") format("svg");
}

/* raleway-200 - latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 200;
    src: url("../fonts/raleway-v22-latin-200.eot");
    src: local(""),
        url("../fonts/raleway-v22-latin-200.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/raleway-v22-latin-200.woff2") format("woff2"),
        url("../fonts/raleway-v22-latin-200.woff") format("woff"),
        url("../fonts/raleway-v22-latin-200.ttf") format("truetype"),
        url("../fonts/raleway-v22-latin-200.svg#Raleway") format("svg");
}

/* raleway-regular - latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/raleway-v22-latin-regular.eot");
    src: local(""),
        url("../fonts/raleway-v22-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/raleway-v22-latin-regular.woff2") format("woff2"),
        url("../fonts/raleway-v22-latin-regular.woff") format("woff"),
        url("../fonts/raleway-v22-latin-regular.ttf") format("truetype"),
        url("../fonts/raleway-v22-latin-regular.svg#Raleway") format("svg");
}

/* raleway-600 - latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/raleway-v22-latin-600.eot");
    src: local(""),
        url("../fonts/raleway-v22-latin-600.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/raleway-v22-latin-600.woff2") format("woff2"),
        url("../fonts/raleway-v22-latin-600.woff") format("woff"),
        url("../fonts/raleway-v22-latin-600.ttf") format("truetype"),
        url("../fonts/raleway-v22-latin-600.svg#Raleway") format("svg");
}

/* raleway-800 - latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 800;
    src: url("../fonts/raleway-v22-latin-800.eot");
    src: local(""),
        url("../fonts/raleway-v22-latin-800.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/raleway-v22-latin-800.woff2") format("woff2"),
        url("../fonts/raleway-v22-latin-800.woff") format("woff"),
        url("../fonts/raleway-v22-latin-800.ttf") format("truetype"),
        url("../fonts/raleway-v22-latin-800.svg#Raleway") format("svg");
}

/* bebas-neue-regular - latin */
@font-face {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/bebas-neue-v2-latin-regular.eot");
    src: local(""),
        url("../fonts/bebas-neue-v2-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/bebas-neue-v2-latin-regular.woff2") format("woff2"),
        url("../fonts/bebas-neue-v2-latin-regular.woff") format("woff"),
        url("../fonts/bebas-neue-v2-latin-regular.ttf") format("truetype"),
        url("../fonts/bebas-neue-v2-latin-regular.svg#BebasNeue") format("svg");
}
